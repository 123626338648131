import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"
import extractActions from "./actions"
import { map } from "lodash-es"

const baseURI = "/companies/drop_off_fees"

const mapFilters = filters => {
  return {
    companies_shop_ids: map(filters.shops, "id"),
    prefecture_ids: map(filters.prefectures, "id"),
    companies_car_type_ids: map(filters.carTypes, "id"),
    search_value: filters.searchValue
  }
}

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withFilters: {
    prefectures: [],
    carTypes: [],
    shops: [],
    searchValue: ""
  },
  withSorting: {
    field: "created_at",
    direction: "desc"
  },
  mapFilters
})

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
